.slide-pane__overlay {
  z-index: 9999;
  padding-top: 10px;
  padding-bottom: 10px;
}
.slidingPenal_z_index {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: none !important;
}
.slide-pane__header {
  border-top-left-radius: 10px;
}
.slide-pane__close {
  rotate: 180deg;
}
h2[data-testid="flowbite-accordion-heading"] {
  display: flex;
}
.slide-pane__header,
.slide-pane__content {
  border: 0px !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(38 102 176 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.slide-pane__content button {
  background-color: #194a83 !important;
  color: white;
  font-weight: 400;
  border: 0px !important;
  border-bottom: 2px solid #1c518d !important;
}
div[data-testid="flowbite-accordion-content"] {
  border: 0px !important;
  background-color: #396393 !important;
}
div[data-testid="flowbite-accordion-content"] p {
  color: white !important;
}
.slide-pane__content .focus\:ring-gray-200:focus {
  --tw-ring-opacity: 0 !important;
}
.slide-pane__close {
  margin-right: 0px !important;
}
.slide-pane__title-wrapper {
  margin-left: 0px !important;
}
.slide-pane__content div[data-testid="flowbite-accordion"] {
  border: 0px !important;
}
.trigger_btn {
  box-shadow: 4px 4px 16px 0px #00000014;
  background: #2566af !important;
  height: 150px;
  border-radius: 24px;
  padding: 6px;
  margin-top: 10rem;
  z-index: 40;
  position: fixed;
  right: 10px;
  top: calc(50vh - 200px);
  color: #fff;
  font-size: 1em;
}
.trigger_btn .cursor-pointer svg {
  height: 25px;
}
.height18 svg {
  height: 20px;
}
