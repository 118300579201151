body{
  background: #FAFAFA;
}

.new_chat_btn{
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #FFFFFF;
  text-decoration: none;
}

.new_faq_btn{
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #FFFFFF;
  text-decoration: none;
}

.accordion-button {
  background-color: #194a83 !important;
  color:white;
  font-weight: 400;
  border-bottom: 2px solid #1c518d !important;
}
.accordion-body{
  background-color: #396393 !important;
  color:white

}
.accordion-header::after{
  color:white;
  background-color: white;
}

.accordion-item{
  border: none !important;
}

.accordion-button:hover {
  background-color: #1c518d !important;
}
.accordion-button:after {
  color: red !important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}


.accordion-button:not(.collapsed) {
  color: white;
  font-weight: 600;
}
.new-line {
  white-space: pre-line;
}

