
.sidebar{
    box-shadow: 4px 4px 16px 0px #00000014;
    background: #2566AF;
    width: 280px;
    height: calc(100vh - 2rem);
    border-radius: 24px;
    padding: 20px;
}

.faq_sidebar{
    box-shadow: 4px 4px 16px 0px #00000014;
    background: #2566AF;
    height: calc(100vh - 73vh);
    border-radius: 24px;
    padding: 6px;
    margin-top: 10rem;
    z-index: 40;

}

.faq_sidebar_open{
    height: calc(100vh - 2rem) !important;
    padding: 15px;
    margin-top: 0;
    z-index: 1000;


}
.faq_list_scroll{
    height: calc(100vh - (170px + 3rem));
    overflow-y: auto;
    overflow-x: auto;
    margin-top: 20px;
}

.progress_logs{
    height: 200px;
    overflow-y: auto;
    overflow-x: auto;
    text-align: left;
    background: black;
    color: #fff;
    border-radius: 16px;
    padding: 15px;
    margin-top: 10px;
}

.file_history_list_scroll, .upload_inner_box label{
    height: calc(100vh - (170px + 12rem));
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.upload_inner_box label{
    height: calc(100vh - (170px + 12.7rem));

}


.upload_inner_box label{
    min-height: 324px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main_section {
    width: calc(100vw - (280px + 2rem));
    padding: 15px 10px 10px 30px;
}

.justify-content-space-beetween{
    justify-content: space-between;
}

.header button{
    border: 0px !important;
    background-color: transparent !important;
}

#dropdown-basic{
    height: 100%;
    padding: 0 10px;
}

#dropdown-basic:after{
    position: relative;
    top: 0px;
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 0.1em solid black;
    border-top: 0.1em solid black;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.title_section{
    display: flex;
    align-items: center;
}

.title_section a{
    margin-right: 10px;
    width: 50px;
    border-radius: 30px;
    border: 1px solid #2566AF;
    padding: 10px;
}

.title_section svg{
    height: 100%;
    color: #2566AF;
}

.title_section h1{
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
}

.title_section p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
}

.static_section{
    position: sticky;
    top: 0;
    background: white;
    padding: 10px 15px;
    border-radius: 16px;
    margin-bottom: 10px;
    box-shadow: 4px 4px 12px 0px #0000000A;
    border: 1px solid #DCE2EA;
}

.model-progress-bar{
    white-space: break-spaces;
}

.min-height-100vh{
    min-height: 100vh;
}

.noWrap{
    white-space: nowrap;
}


div.card{
    padding: 30px;
    border-radius: 24px;
    box-shadow: 4px 4px 12px 0px #0000000A;
    border: 0px;
}

.file_history_list_scroll{
    background: linear-gradient(180deg, rgba(37,102,175,0.1) 1%, rgba(37,102,175,0) 99%);
    border: 2px dashed #2566AFB2;
    padding: 10px;
    border-radius: 16px;
}

.upload_inner_box{
    background: linear-gradient(180deg, rgba(37,102,175,0.1) 1%, rgba(37,102,175,0) 99%);
    border: 2px dashed #2566AFB2;
    border-radius: 16px;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
}

.upload_inner_box p{
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    color: #667085;
}

.file_upload_btn{
    border: 0px;
    padding: 8px 12px 8px 12px;
    border-radius: 12px;
    gap: 10px;
    background: #2566AF;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #FFFFFF;
}

.col-sm-6.m-auto{
    margin: auto;
}

.proccess_box{
    display: flex;
    padding: 15px 25px;
    border-radius: 16px;
    border: 1px solid #DCE2EA;
    gap: 18px;
    background: linear-gradient(180deg, rgba(37,102,175,0.04) 0%, rgba(37,102,175,0) 99%);
    align-items: center;
}

.fileLoder{
    border: 6px solid #11BF85; /* Light grey */
    border-top: 6px solid rgb(17 191 133 / 15%); /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.default_theme_btn{
    padding: 15px 20px 15px 20px;
    min-width: 190px;
    border-radius: 12px;
    gap: 10px;
    background-color: #2566AF;
    border: 0px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #fff;
    box-shadow: 0px 1px 2px 0px #005D5412;
}

.default_theme_btn:disabled{
    color: #667085 !important;
    background: #E8E8E8 !important;
}

.model-progress-bar label{
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 10px;
}

.questions_list {
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
}

.questions_list li{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 12px;
    border: 1px;
    gap: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #2566AF;
    border: 1px solid #DCE2EA;
    align-items: center;
    margin-bottom: 15px;
}

.content_section{
    height: calc(100vh - 180px);
    overflow-x: hidden;
    position: relative;
    overflow-y: auto;
}

.input_seaction{
    position: relative;
}
.input_seaction input{
    height: 60px;
    border-radius: 12px;
}
.input_seaction button{
    position: absolute;
    right: 15px;
    top: 15px;
    border: 0px;
    background-color: transparent !important;
}


.input_seaction button svg{
    height: 25px !important;
    width: 25px !important;
}

.question_card{
    padding: 20px;
    border-radius: 12px;
    border: 1px;
    gap: 16px;
    box-shadow: 4px 4px 16px 0px #0000000A;
    border: 1px solid #DCE2EA;
    background: #f3f4f5;
    margin-bottom: 20px;
}

.question_text img{
    margin-right: 15px;
}

.question_text{
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    margin-bottom: 20px;
}

.answer_box{
    padding: 16px;
    border-radius: 12px;
    background:#FFFFFF;
    border: 1px solid #DCE2EA;
    gap: 16px;
    box-shadow: 4px 4px 12px 0px #0000000A;
    overflow-x: hidden;
}

.historyList, .history_section ul{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
}

/* 
.search_question{
    align-items: center;
    position: sticky;
    bottom: 0px;
    right: 0px;
    left: 0;
    top: 100%;
} */

.search_question {
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 7rem;
    left: 20rem;
}

.migrobutton{
    border: 0px;
    background: #2566AF;
    box-shadow: 0px 4px 4px 0px #0000000D;
    border-radius: 12px;
    margin-left: 10px;
    height: 60px;
    width: 60px;
}

.questions_list li p {
    margin-bottom: 0px;
}

.model-progress-bar .progress{
    background: #F2F2F2 !important;
}

.model-progress-bar .progress-bar{
    background: linear-gradient(90deg, #FE8551 0%, #ED618E 100%) !important;
}

.display-inline-block{
    display: inline-block;
}

.align-center{
    align-items: center;
}

.f20{
    font-size: 20px !important;
}

.bg-white{
    background-color: #fff !important;
}

.red-color{
    color: #EB1414 !important;
}

.m-50{
    margin: 50px;
}

.mb-6{
    margin-bottom: 6rem;
}

.float-right{
    float:right;
}

.flex-1{
    flex:1;
}

.align-item-center{
    align-items: center;
}

.decoration-none{
    text-decoration: none;
}

.close_btn{
    margin-left: 15px;
}

.decoration_none{
    text-decoration: none !important;
}

.progress{
    --bs-progress-height: 0.5rem !important;
    background-color: #D9D9D9 !important;
}

.progress-bar{
    border-radius: var(--bs-progress-border-radius) !important;
    background-color: #2566AF !important;
}

.close_btn svg{
    height: 22px;
    width: 22px;
}

.transparentBtn{
    border: 0px;
    background-color: transparent;
}

.file_details h5 span{
    display: inline-block;
    width: 100% !important;
    color: #667085;
    margin-top: 5px;
}

.svg-inline--fa.fa-circle-xmark {
    color: #e4252a;
    height: 30px;
    width: 30px;
}

.history_section{
    padding-top: 15px;
    margin-top: 15px;
    /* border-top: 1px solid #fff; */
    border-top: 1px solid;

    /* Set the border image source with a gradient */
    border-image-source: linear-gradient(270deg, rgba(255, 255, 255, 0) 6.39%, rgba(255, 255, 255, 0.26) 52.2%, rgba(255, 255, 255, 0) 97.38%);

    /* Specify how the image slices are filled */
    border-image-slice: 1;

    /* Specify the border image width */
    border-image-width: 1;

    /* Specify the border image outset */
    border-image-outset: 0;
}

.questionList_box{
    padding: 10px 10px 10px 20px !important;
}
.days_title{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #E4E4E4;
}

.history_section ul{
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.history_section ul li a{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
    text-decoration: none;
}

.history_section ul li svg{
    margin-right: 10px;
    height: 34px;
    min-width: 34px;
    display: inline-block;
}


/* checkkbox */
.round {
    position: relative;
  }
  
  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }
  
  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color: #2566AF;
    border-color: #2566AF;
  }

  .round input[type="checkbox"]:checked:disabled + label {
    background-color: #13bf85;
    border-color: #2566AF;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .mr-2{
    margin-right: 1rem;
  }

.ans_table table{
    border-collapse: separate !important;
    border-spacing: 0 !important;
}

.ans_table table thead tr th{
    background-color: #b3bbc3;
    font-size: 15px;
    font-weight: 800;
    line-height: 18px;
    color: #667085;
    padding: 12px 24px 12px 24px;
}

.ans_table table tr td{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 24px 16px 24px;
    color: #101828;
  
}


.ans_table table tr td, .ans_table table thead tr th{
    border: 1px solid #EAECF0;
}

.ans_table table thead tr:first-child th:first-child{
    border-top-left-radius: 10px;
}

.ans_table table thead tr:first-child th:last-child{
    border-top-right-radius: 10px;
}

.ans_table table tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.ans_table table tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.modalButtons button{
    flex:1;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
}

.btn-white{
    border: 1px solid #EAECF0 !important;
    color: #1B254B !important;
    box-shadow: 0px 1px 2px 0px #005D5412;
    background-color: #fff !important;
}

.modal-body h4{
    font-size: 30px;
    font-weight: 700;
    line-height: 41px;
}

.defaultFont{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.footer{
    height: 60px;
    color: #2466af;
    font-weight: 600;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }