.errorDisply{
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
}
.errorTitle{
    flex: 1;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ff6600;
}