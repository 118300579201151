.chatLoder{ 
    display: flex;
    align-items: center;
    justify-content: center;
  } 

  .graph_section svg{
    /* width: 100% !important; */
    /* height: 400px !important; */
  }
  /* Creating the dots */ 
 .chatLoder span{ 
    height: 20px; 
    width: 20px; 
    margin-right: 8px; 
    border-radius: 50%; 
    background-color: #2566AF; 
    animation: chatloading 1s linear infinite; 
  } 
  /* Creating the loading animation*/ 
  @keyframes chatloading { 
    0%{ 
     transform: translateX(0); 
    } 
    25%{ 
     transform: translateX(15px); 
    } 
    50%{ 
     transform: translateX(-15px); 
    } 
    100%{ 
     transform: translateX(0); 
    } 
      
  } 
  .chatLoder span:nth-child(1){ 
   animation-delay: 0.1s; 
 } 
 .chatLoder span:nth-child(2){ 
   animation-delay: 0.2s; 
 } 
 .chatLoder span:nth-child(3){ 
   animation-delay: 0.3s; 
 } 
 .chatLoder span:nth-child(4){ 
   animation-delay: 0.4s; 
 } 
 .chatLoder span:nth-child(5){ 
   animation-delay: 0.5s; 
 } 